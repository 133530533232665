<template>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-lg-4 mt-3 p-4 form-wrapper bg-dark shadow">
        <h2 class="text-primary text-center mb-4">Welcome Back!</h2>

        <form @submit.prevent="login">
          <!-- Email Input -->
          <div class="form-floating mb-3">
            <input type="email" class="form-control" v-model="email" required placeholder="Email" />
            <label for="email" class="form-label">Email address</label>
          </div>

          <!-- Password Input -->
          <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" v-model="password" required minlength="8" placeholder="Password" />
            <label for="password" class="form-label">Password</label>
            <span class="password-toggle" @click="togglePassword">
              <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
            </span>
          </div>

          <!-- Error Message -->
          <p class="alert alert-danger text-center" v-if="errorMessage">{{ errorMessage }}</p>

          <!-- Submit Button -->
          <button type="submit" class="btn btn-primary w-100 mb-3">Sign in</button>

          <!-- Links -->
          <div class="text-center">
            <router-link class="text-muted" to="/register">Don't have an account yet?</router-link>
            <span class="mx-2">|</span>
            <router-link class="text-muted" to="/reset-password">Forgot Password?</router-link>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .form-wrapper {
    border-radius: 10px;
  }

  .form-wrapper:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }

  .text-primary {
    color: #007bff;
  }

  .alert-danger {
    color: #dc3545;
    border-color: #dc3545;
  }

  .text-muted {
    color: #6c757d;
  }

  .position-relative {
    position: relative;
  }

  .password-toggle {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
  }

  .password-toggle:hover {
    color: #007bff;
  }
</style>

<script>
import axios from 'axios';
import router from '@/router'; 

export default {
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      errorMessage: '',
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    login() {
      const data = {
        email: this.email,
        password: this.password,
      };

      axios
        .post('/login', JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.log(response.data);

          if (response.data.message === 'Login successful') {
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("id", response.data.id);
            sessionStorage.setItem("book_id", response.data.book_id);

            if (response.data.role === 'admin') {
              console.log('Redirecting to admin panel');
              router.push('/admin2');
            } else {
              console.log('Redirecting to user panel');
              router.push('/user');
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = 'Invalid email or password, try again!';
          setTimeout(() => {
            this.errorMessage = '';
          }, 1000); 
        });
    },
  },
};
</script>
